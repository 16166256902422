
export const byId = document.getElementById.bind(document);
export const ce = document.createElement.bind(document);

export const ceClass = (el: string, classes: string|string[]) => {
    const e = ce(el);
    if (typeof classes === "string") {
        e.classList.add(classes as string);
    } else {
        for (const c of (classes as string[])) {
            e.classList.add(c);
        }
    }
    return e;
}
export const ct = document.createTextNode.bind(document);
export const ca = (href: string, text: string) => {
    const a = ce('a');
    a.href = href;
    a.innerText = text;
    return a;
}
export const ci = (type: string) =>  {
    const i = ce('i');
    i.classList.add('bi', `bi-${type}`);
    return i;
}
export const cb = (text: string, onclick: () => void, cls?: string|string[], icon?: string, size?: 'sm' | 'lg'): HTMLButtonElement => {
    const a = ceClass('button', 'text-nowrap') as HTMLButtonElement;
    if (icon) {
        a.appendChild(ci(icon));
        a.appendChild(ct('  ' + text));
    } else {
        a.appendChild(ct(text));
    }
    a.classList.add('btn', ...(cls ? (typeof cls === "string" ? [cls] : cls) : ['btn-primary']));
    if (size) {
        a.classList.add(`btn-${size}`);
    }
    a.addEventListener('click', (e) => {e.preventDefault(); onclick()});
    return a;
}
export const createTable = (headers?: string[], cls?: string[]): HTMLTableElement => {
    const table = ceClass('table', ['table', 'table-striped', 'table-hover', ...(cls ? cls : [])]) as HTMLTableElement;
    if (headers) {
        const thead_row = table.appendChild(ce('thead')).appendChild(ce('tr'));
        for (const h of headers) {
            thead_row.appendChild(ce('th')).innerText = h;
        }
    }
    return table;
}
export const createTextInput = (text: string, value: string, id: string, name?: string) => {
    const result = ce('div');
    const label = result.appendChild(ceClass('label', 'form-label')) as HTMLLabelElement;
    label.innerText = text;
    label.htmlFor = id;
    const input = result.appendChild(ceClass('input', 'form-control')) as HTMLInputElement;
    input.type = 'text';
    input.value = value;
    input.id = id;
    if (name) input.name = name;
    return result;
}

export const createSelect = (text: string, value: string, options: [string, string][], id: string, name?: string) => {
    const result = ce('div');
    const label = result.appendChild(ceClass('label', 'form-label')) as HTMLLabelElement;
    label.innerText = text;
    label.htmlFor = id;
    const input = result.appendChild(ceClass('select', 'form-select')) as HTMLSelectElement;
    input.id = id;
    for (const option of options) {
        const o = input.appendChild(ce('option'));
        o.value = option[0];
        o.innerText = option[1];
        o.selected = option[0] == value;
    }
    if (name) input.name = name;
    return result;
}


export const ckv = (key: string, value: string | number, span?: HTMLElement) => {
    span = span || ce('span');
    span.appendChild(ce('b')).innerText = key + ": "
    span.appendChild(ct(''+value));
    return span;
}