import {ce} from "./view/utils";

// Executes given block eventually (when webpage has fully loaded)
export function executeEventually(block: (() => void), times = 5): Promise<void>{
    if (times <= 0) {
        return new Promise(resolve => {
            block();
            resolve();
        });
    } else {
        return new Promise(resolve => {
            setTimeout(() => {
                executeEventually(block, times -1).then(() => {resolve()});
            }, 0)
        });
    }
}

export const emptyPromise = () => new Promise<void>(resolve => resolve());

let loadedJS: {[path: string]: Promise<void>} = {};

export function loadJS(path: string): Promise<void> {
    if (loadedJS[path] == undefined) {
        loadedJS[path] = new Promise<void>((resolve, reject) => {
            console.log(`loading ${path}`)
            const script = ce('script');
            script.setAttribute('src', path);
            script.setAttribute('type', 'text/javascript');
            script.setAttribute('async', 'true');
            script.addEventListener('load', pdf => {
                console.log(`loaded ${path}`)
                resolve();
            });
            script.addEventListener('error', pdf => {
                delete loadedJS[path];
                reject();
            });
            document.body.appendChild(script);
        })
    }

    return loadedJS[path];
}

export function compare(a: string, b: string): number {
    return a.toLocaleLowerCase('pl').localeCompare(b.toLocaleLowerCase('pl'), 'pl')
}

export function timeSince(date: number): string {
    const seconds = Math.floor((Date.now() - date) / 1000);
    let interval = seconds / 86400;
    if (interval > 2) return `${Math.floor(interval)} days ago`;
    interval = seconds / 3600;
    if (interval > 2) return `${Math.floor(interval)} hours ago`;
    interval = seconds / 60;
    if (interval > 2) return `${Math.floor(interval)} minutes ago`;
    if (seconds == 1) return `1 second ago`
    return `${seconds} seconds ago`;
}

export function datetimeWithSince(date: number): string {
    return `${new Date(date).toLocaleString('pl', {})} (${timeSince(date)})`;
}
