import * as Consts from "./consts";
//import {byId} from "./view/utils";
import {executeEventually} from "./utils";
import {installFooter} from "./controller/footer";
import {Collapse} from "bootstrap";
//import {Alert} from "bootstrap";


window.onload = function () {
    console.log(`main ${Consts.VERSION}`);
    // window.history.replaceState({}, '');
    // const root = byId('root')!;
    // root.classList.add('loaded');

    for (const collapse of Array.from(document.getElementsByClassName('collapse'))) {
        new Collapse(collapse, {toggle: false});
    }
    //new Alert(byId('new_cd')!);

    return executeEventually(() => {
        installFooter();
    });
}